import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { APP_BASE_HREF } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from "./../environments/environment";

import { rootRoutes } from "./app.routes";

// import { CoreModule } from "./core/core.module";
// import {SharedModule} from './shared/shared.module';
import { SharedModule } from "./shared/shared.module";
import { ApiModule } from "./api";
import { WINDOW_PROVIDERS } from "./api/services/window.service";
import { InterceptInterceptor } from "./interceptors/intercept.interceptor";
import { NewPasswordComponent } from "./auth/components/new-password/new-password.component";

@NgModule({
  declarations: [AppComponent, NewPasswordComponent],
  imports: [
    ApiModule,
    // BrowserModule.withServerTransition({ appId: "my-app" }),
    HttpClientModule,
    RouterModule.forRoot(rootRoutes, {
      // enableTracing :true, // For debugging
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "disabled",
      initialNavigation: "enabledBlocking",
      useHash: false,
    }),
    // CoreModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    // {
    //   provide: APP_BASE_HREF,
    //   useValue: `${environment.BASE_URL}`,
    // },
    { provide: "googleTagManagerId", useValue: environment.GTM_ID },
    WINDOW_PROVIDERS,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
